.Not-Found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Not-Found-Image {
  height: 800px;
  width: 800px;
}

.Not-found-heading {
  color: #475569;
  font-size: 30px;
  font-family: 'Roboto';
}

.Not-found-para {
  color: #64748b;
  font-size: 20px;
  font-family: 'Roboto';
}
